import { createApp } from 'vue'
import { createPinia } from "pinia";
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import App from './App.vue'
import router from '@/router'

import VueApexCharts from "vue3-apexcharts";
import PrimeVue from "primevue/config";
import ToastService from "primevue/toastservice";
import Toast from "primevue/toast";
import ConfirmationService from 'primevue/confirmationservice';
import ConfirmPopup from "primevue/confirmpopup";
import ConfirmDialog from "primevue/confirmdialog";
import Tooltip from 'primevue/tooltip';
import VueNumberFormat from "vue-number-format";


import 'element-plus/dist/index.css'
import "filepond/dist/filepond.min.css"
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css"
import "primevue/resources/themes/lara-light-indigo/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import './assets/css/dashlite.css'
import './assets/css/theme-green.css'
import './assets/css/themify-icons.css'
import '@vueform/multiselect/themes/default.css'

import 'element-plus/es/components/message-box/style/css'
import 'element-plus/es/components/collapse/style/css'
import 'element-plus/es/components/collapse-item/style/css'

import './style.css'


window.addEventListener('resize', () => {
    if (window.innerWidth > 997) {
        const overlay = document.querySelector('.nk-sidebar-overlay')
        overlay.style.display = "none"
    }
})

window.addEventListener('vite:preloadError', (event) => {
    console.log("preload error", event)
    window.location.reload()
})

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

const app = createApp(App);

app.use(router)
app.use(pinia)

// PrimeVue Usages
app.use(PrimeVue)
app.use(ToastService);
app.use(ConfirmationService);

// PrimeVue Components
app.component("Toast", Toast)
app.component('ConfirmPopup', ConfirmPopup)
app.component('ConfirmDialog', ConfirmDialog)

app.use(VueApexCharts);

// Vue Number Format Usage
app.use(VueNumberFormat)

app.directive('tooltip', Tooltip);


app.mount('#app');



